
import Vue from "vue";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { IAllergen, IAllergenRES, EMPTY_ALLERGEN } from "@common/allergen";
import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";
import router from "@/router";

export default Vue.extend({
	name: "EditAllergen",
	data: () => ({
		process: false as boolean,
		allergen: EMPTY_ALLERGEN as IAllergen,
		oldAllergen: EMPTY_ALLERGEN as IAllergen,
	}),
	created: async function () {
		this.store.dispatch.changeAppTitle("Edit allergen");
		if (this.store.getters.allergens.length === 0) {
			await this.store.dispatch.fetchAllergens();
		}
		for (const allergen of this.store.getters.allergens) {
			if (allergen.id === this.$route.query.id) {
				this.oldAllergen = allergen;
				this.allergen = { ...allergen };
			}
		}
	},
	methods: {
		async saveAllergen () {
			this.process = true;

			try {
				const data: IAllergen = this.allergen;
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					data,
					url: `${this.store.getters.serverURL}/allergen/update`,
				};
				const res: AxiosResponse<IServerRES<IAllergenRES>> = await axios(options);
				if (res.data.err === ServerError.NO_ERROR) {
					if (res.data.payload.succeeded) {
						this.store.dispatch.editAllergen({
							old: this.oldAllergen,
							new: res.data.payload.allergen
						});
					}
				} else {
					// TODO ERROR DISPLAY
					console.error(new Error(res.data.payload.message));
				}
			} catch (err) {
				// TODO ERROR DISPLAY
				console.error(err);
			}

			router.push({ path: "/allergens" });
		},
	},
});

import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"col-md-8",attrs:{"justify":""}},[_c(VCard,{attrs:{"disabled":_vm.process,"elevation":"0"}},[_c(VCardText,[_c('h2',{staticClass:"font-weight-regular"},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("$vuetify.icons.generalInfo")]),_vm._v("General Info")],1),_c(VDivider,{staticClass:"mt-2"}),_c(VContainer,[_c(VTextField,{attrs:{"label":"ID","disabled":"","prepend-icon":"create"},model:{value:(_vm.allergen.id),callback:function ($$v) {_vm.$set(_vm.allergen, "id", $$v)},expression:"allergen.id"}}),_c(VTextField,{attrs:{"label":"Name","prepend-icon":"title"},model:{value:(_vm.allergen.name),callback:function ($$v) {_vm.$set(_vm.allergen, "name", $$v)},expression:"allergen.name"}}),_c(VTextField,{attrs:{"label":"Printable Name","prepend-icon":"title"},model:{value:(_vm.allergen.printableName),callback:function ($$v) {_vm.$set(_vm.allergen, "printableName", $$v)},expression:"allergen.printableName"}})],1)],1),_c(VCardActions,{staticClass:"pt-0"},[_c(VSpacer),_c(VBtn,{attrs:{"depressed":"","color":"success"},on:{"click":_vm.saveAllergen}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("save")]),_vm._v("Save Changes ")],1),_c(VBtn,{attrs:{"depressed":"","color":"error","to":"/allergens"}},[_c(VIcon,[_vm._v("close")]),_vm._v("Cancel ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }